import { format } from 'date-fns';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import _ from 'lodash';
import { lighten } from 'polished';
import React from 'react';

import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { colors } from '../styles/colors';
import { PageContext } from '../templates/post';
import { AuthorList } from './AuthorList';

export const BookCard = ({ post, large = false }) => {

  return (
    <article
      className={`post-card no-image'`}
      css={[PostCardStyles, large && PostCardLarge]}
    >
      <PostCardContent className="post-card-content">
        <Link className="post-card-content-link" css={PostCardContentLink} to={post.slug}>
    <div>

        {
          post.author ? (<div>
          <PostCardHeader className="post-card-header"  >
            <PostCardTitle className="post-card-title">{post.title}</PostCardTitle>
          </PostCardHeader>

            <div style={{textAlign:"center", color: "#90a2aa", margin: "-10px"}}>
            by
            </div>

                  <PostCardHeader className="post-card-header" style={{marginBottom: '15px', marginTop: '10px'}}>
                    <PostCardAuthor className="post-card-title">{post.author}</PostCardAuthor>
                  </PostCardHeader>
          </div>
) : (<>
          <PostCardHeader className="post-card-header" style={{marginBottom: '15px'}}>
            <PostCardTitle className="post-card-title">{post.title}</PostCardTitle>
          </PostCardHeader>

  </>)
        }

    </div>
        </Link>
      </PostCardContent>
    </article>
  );
};

const PostCardStyles = css`

  position: relative;
  flex: 1 1 301px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 20px 40px;
  //padding: 0 20px 40px;
  /* border-bottom: 1px solid color(var(--lightgrey) l(+12%)); */
  //border-bottom: 1px solid ${lighten('0.12', colors.lightgrey)};
  background-size: cover;

  @media (max-width: 500px) {
    padding: 0;
    margin-bottom: 20px;
  }

  //@media (prefers-color-scheme: dark) {
    /* border-bottom-color: color(var(--darkmode) l(+8%)); */
    border-bottom-color: ${lighten('0.08', colors.darkmode)};
  //}
  //
  //
`;


const PostCardImageLink = css`
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
`;

const PostCardImage = styled.div`
  width: auto;
  height: 200px;
  background: ${colors.black} no-repeat center center;
  background-size: cover;

  .gatsby-image-wrapper {
    picture {
      img {
       object-fit: contain !important;
       filter: brightness(75%) !important;
      }
    }
  }
`;

const PostCardContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  //background: #060606;
  display: flex;
  min-height: 50px;
    justify-content: center;
    align-items: center;



    //box-shadow: 0 0 20px rgba(62, 176, 239, 0.1);
    //backdrop-filter: blur(2px);
    //border: 1px solid rgba(62, 176, 239, 0.1);
    border-radius: 10px;

  //box-shadow:
    //inset 0 0 25px  rgba(255, 255, 255, 0.01),      /* inner white */
    //inset 20px 0 40px rgba(255, 255, 255, 0.01),   /* inner left magenta short */
    //inset -20px 0 40px  rgba(255, 255, 255, 0.01),  /* inner right cyan short */
    //inset 20px 0 150px rgba(255, 255, 255, 0.01),  /* inner left magenta broad */
    //inset -20px 0 150px rgba(255, 255, 255, 0.01); /* inner right cyan broad */

  //background: rgba(25, 25, 25, 1);

    background: rgba(0, 0, 0, 0.9);
    //box-shadow: 
      //inset 0 0 15px rgba(100, 100, 100, .5);
      //border: 1px solid rgba(255, 255, 255, 0.25);;

  //padding: 0 20px;
`;

const PostCardContentLink = css`
  position: relative;
  display: block;
  /* color: var(--darkgrey); */
  color: ${colors.darkgrey};
  width: 100%;
  height: 100%;

  :hover {
    text-decoration: none;
  }
`;

const PostCardPrimaryTag = styled.div`
  margin: 0 0 0.2em;
  /* color: var(--blue); */
  color: ${colors.blue};
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-transform: uppercase;
`;

const PostCardTitle = styled.h2`
  text-align: center;
  margin: 0 0 0.4em;
  line-height: 1.15em;
  transition: color 0.2s ease-in-out;

  //@media (prefers-color-scheme: dark) {
    color: rgba(255, 255, 255, 0.85);
  //}
`;

const PostCardAuthor = styled.h3`
  text-align: center;
  margin: 0 0 0.4em;
  line-height: 1.15em;
  transition: color 0.2s ease-in-out;

  //@media (prefers-color-scheme: dark) {
    color: rgba(255, 255, 255, 0.85);
  //}
  color: #90a2aa
`;

const PostCardExcerpt = styled.section`
  font-family: Georgia, serif;

  //@media (prefers-color-scheme: dark) {
    /* color: color(var(--midgrey) l(+10%)); */
    color: ${lighten('0.1', colors.midgrey)} !important;
  //}
`;

const PostCardMeta = styled.footer`
  display: flex;
  align-items: flex-start;
  padding: 0;
`;

const PostCardBylineContent = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  margin: 4px 0 0 10px;
  /* color: color(var(--midgrey) l(+10%)); */
  color: ${lighten('0.1', colors.midgrey)};
  font-size: 1.2rem;
  line-height: 1.4em;
  font-weight: 400;
  letter-spacing: 0.2px;
  text-transform: uppercase;

  span {
    margin: 0;
  }

  a {
    /* color: color(var(--darkgrey) l(+20%)); */
    color: ${lighten('0.2', colors.darkgrey)};
    font-weight: 600;
  }

  //@media (prefers-color-scheme: dark) {
    a {
      color: rgba(255, 255, 255, 0.75);
    }
  //}
`;

const PostCardHeader = styled.header`
  margin: 15px 0 0;
`;

export const StaticAvatar = css`
  display: block;
  overflow: hidden;
  margin: 0 0 0 -6px;
  width: 34px;
  height: 34px;
  border: #fff 2px solid;
  border-radius: 100%;

  //@media (prefers-color-scheme: dark) {
    /* border-color: color(var(--darkgrey) l(+2%)); */
    border-color: ${lighten('0.02', colors.darkgrey)};
  //}
`;

export const AuthorProfileImage = css`
  display: block;
  width: 100%;
  height: 100%;
  /* background: color(var(--lightgrey) l(+10%)); */
  background: ${lighten('0.1', colors.lightgrey)};
  border-radius: 100%;
  object-fit: cover;

  //@media (prefers-color-scheme: dark) {
    background: ${colors.darkmode};
  //}
`;
