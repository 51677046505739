import { graphql } from 'gatsby';
import { FixedObject } from 'gatsby-image';
import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/core';
import Img from "gatsby-image"

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import Pagination from '../components/Pagination';
import { PostCard } from '../components/PostCard';
import { TopicCard } from '../components/TopicCard';
import { BookCard } from '../components/BookCard';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  PostFeed,
  Posts,
  SiteDescription,
  SiteHeader,
  SiteHeaderContent,
  SiteMain,
  SiteNavMain,
  SiteTitle,
  SiteHeaderStyles,
  SiteQuote,
} from '../styles/shared';
import config from '../website-config';
import { PageContext } from './post';

import { Stars } from '../components/Stars';
import { Canvas } from 'react-three-fiber'

const SectionTitle = css`
  //font-family: 'Blanka';
  text-align: center;
  //text-shadow: 0 0 50px rgba(255,255,255,0.3), 2px 0 1px rgba(255,255,255,0.3), -2px 0 10px rgba(255,255,255,0.3), 2px 0 10px rgba(255,255,255,0.3), -2px 0 10px rgba(255,255,255,0.3);
  margin: 2.5em 0 2.5em 0;
  color: #fff;
  font-size: 2.8rem;
`
const BookAdjust = css`

  @media (min-width: 500px) {
   margin-bottom: -20px
 }
`

const IndexPage = (props) => {
  const { width, height } = props.data.header.childImageSharp.fixed;
  const homepageTags = [ {
    title: "Free Will",
    slug: "topics/free-will/"
  }, {
    title: "Buddhism",
    slug: "topics/buddhism/"
  }, {
    title: "The Buddha",
    slug: "topics/the-buddha/"
  }, {
    title: "Determinism",
    slug: "topics/determinism/"
  }, {
    title: "Indeterminism",
    slug: "topics/indeterminism/"
  }, {
    title: "Consciousness",
    slug: "topics/consciousness/"
  }, {
    title: "All Topics",
    slug: "topics/"
  }];


  const homepageBooks = [ {
    title: "Meditations",
    author: "Marcus Aurelius",
    slug: "texts/meditations/"
  }, {
    title: "Determinism or Free-Will?",
    author: "Chaptman Cohen",
    slug: "texts/determinism-or-free-will/"
  },{
    title: "Relativity: The Special and General Theory",
    author: "Albert Einstein",
    slug: "texts/relativity-the-special-and-general-theory/"
  },
  {
      title: 'Dīgha Nikāya ("The Collection of Long Discourses")',
      author: "",
      slug: "/texts/tipitaka/sutta-pitaka/digha-nikaya/"
    },
    {
      title: 'Majjhima Nikāya ("The Collection of Middle-length Discourses")',
      author: "",
      slug: "/texts/tipitaka/sutta-pitaka/majjhima-nikaya/"
    },
    {
    title: "The Dhammapada",
    author: "",
    slug: "texts/the-dhammapada/"
  },
    {
    title: "Life of Buddha",
    author: "Aśvaghoṣa",
    slug: "texts/life-of-buddha/"
  },
    {
    title: "On the Nature of Things",
    author: "Titus Lucretius Carus",
    slug: "texts/on-the-nature-of-things/"
  },
    {
    title: "The Ethics",
    author: "Benedict de Spinoza",
    slug: "texts/on-the-nature-of-things/"
  },
/*    {
    title: "Human, All Too Human",
    author: "Friedrich Nietzsche",
    slug: "texts/on-the-nature-of-things/"
  }, */
    {
    title: "All Texts",
    slug: "texts/"
  }];


  console.log(props);

  return (
    <IndexLayout css={HomePosts}>
      <Helmet>
        <html lang={config.lang} />
        <title>{config.title + " - Exploring the nature of the mind, reality, and connected nature of the universe."}</title>
        <meta name="description" content={config.description} />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={config.title + " - Exploring the nature of the mind, reality, and connected nature of the universe."} />
        <meta property="og:description" content={config.description} />
        <meta property="og:url" content={config.siteUrl} />
        <meta
          property="og:image"
          content={`${config.siteUrl}${props.data.header.childImageSharp.fixed.src}`}
        />
        {config.facebook && <meta property="article:publisher" content={config.facebook} />}
        {config.googleSiteVerification && (
          <meta name="google-site-verification" content={config.googleSiteVerification} />
        )}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={config.title + " - Exploring the nature of the mind, reality, and connected nature of the universe."} />
        <meta name="twitter:description" content={config.description} />
        <meta name="twitter:url" content={config.siteUrl} />
        <meta
          name="twitter:image"
          content={`${config.siteUrl}${props.data.header.childImageSharp.fixed.src}`}
        />
        {config.twitter && (
          <meta
            name="twitter:site"
            content={`@${config.twitter.split('https://twitter.com/')[1]}`}
          />
        )}
        <meta property="og:image:width" content={width.toString()} />
        <meta property="og:image:height" content={height.toString()} />
      </Helmet>
      <Wrapper>
        <header
          css={[outer, SiteNavMain, ]}
          className="site-header"
        >
          <div css={inner}>
            <SiteNav isPost post={ {title: config.title}} />
          </div>
        </header>

    {/*
        <Canvas 
          camera={{ fov: 240, position: [0, 0, -0] }}
          style={{position:'fixed', width:'100%', height: '100%'}}>
          <Stars></Stars>
        </Canvas>
    */}
        <main id="site-main" css={[SiteMain, outer]}>

            <SiteHeaderContent className="site-header-conent">

              <SiteTitle className="site-title">
                {props.data.logo ? (
                  <Img
                    style={{ 
                      maxWidth: 300,
                        //filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 1))',
                      marginLeft: 'auto',
                        marginRight: 'auto'
                    }}
                    loading='eager'
                    fluid={props.data.logo.childImageSharp.fluid}
                    alt={config.title}

                    placeholderStyle={{ visibility: "hidden" }}
                  fadeIn={false}
                  />
                ) : (
                  config.title
                )}
                <br/>
              </SiteTitle>


                <div style={{
                  display:'block',
                    padding: '40px 0',
                    opacity: 0.75,
                    //borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                    //marginBottom: '40px',
                    //background: 'rgba(0, 0, 0, 01)',
                      //border: '#3eb0ef 1px solid',
                      padding: '40px',
                      borderRadius: '10px',

    fontFamily: 'Georgia, Serif',
      fontSize: '1.8rem',
  marginTop: '40px',
      marginBottom: '40px'
                }}>
                  <p style={{
                      fontFamily: 'Georgia, Serif',
                    }} className="topic-description">
                    <q style={{
                      fontSize: '2rem',
                       color: 'rgba(255,255,255,0.85)',
                      textAlign: 'center', display: 'block', opacity: 1
                    }} >
                      <i>From true emptiness, the wondrous being appears.</i>
                     <br/> - Shunryu Suzuki
                    </q>
                  </p>
                </div>

                <SiteDescription style={{textAlign:'center'}} className="site-description">


                </SiteDescription>
            </SiteHeaderContent>
            <div css={[inner, Posts]}>

              <div css={[PostFeed]} style={{padding:0, margin: 0, height: '100%'}}>



                <PostCard style={{
                }} key={"the mirror"} outline={false} post={
                  {
                  frontmatter: {
                    title: "The Mirror",
                    excerpt: "No matter how many planets and stars are reflected in a lake, these reflections are encompassed within the water itself - Dudjom Lingpa",
                    picture: {
                      childImageSharp: {
                        fixed: props.data.mirror.childImageSharp.fixed
                      }
                    }
                  },
                  fields: {
                    slug: "posts/the-mirror"
                  }
                }
                } large={true} />


                <PostCard style={{
                }} key={"perfect brilliant stillness"} outline={true} post={
                  {
                  frontmatter: {
                    title: "Perfect Brilliant Stillness",
                    excerpt: "(beyond the individual self)\n by David Carse",
                    picture: {
                      childImageSharp: {
                        fixed: props.data.pbs.childImageSharp.fixed
                      }
                    }
                  },
                  fields: {
                    slug: "texts/perfect-brilliant-stillness/the-fine-print"
                  }
                }
                } large={false} />





                {props.data.allMarkdownRemark.edges.map((post, index) => {
                  // filter out drafts in production
                  return (
                    (post.node.frontmatter.draft !== true ||
                      process.env.NODE_ENV !== 'production') && (
                      <PostCard key={post.node.fields.slug} post={post.node} large={index === 0} />
                    )
                  );
                })}
              </div>





    <h2 css={[SectionTitle]} >
    TEXTS
    </h2>

              <div css={[PostFeed, BookAdjust]} style={{padding:0, paddingTop: 20,}}>

                {homepageBooks.map((book) => {
                  // filter out drafts in production
                  return (

                    <BookCard key={book.slug} post={book} />
                    )
                })}
              </div>


    {/*
    <h2 css={[SectionTitle]} >
    TOPICS
    </h2>

              <div css={[PostFeed]} style={{padding:0, paddingTop: 20}}>

                {homepageTags.map((topic) => {
                  // filter out drafts in production
                  return (

                    <TopicCard key={topic.slug} post={topic} />
                    )
                })}
              </div>

    */
    }


            </div>
        </main>
        {props.children}
        {props.pageContext.numPages > 1 && (
          <Pagination
            currentPage={props.pageContext.currentPage}
            numPages={props.pageContext.numPages}
          />
        )}
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};


// We follow the path carved over thousands of years by the greats that came before us. What does it mean to say that all things are empty? What does an empty universe imply about the nature of awareness, free will, the self, space, and time? Are each of us connected in a way only we're only beginning to understand? The journey of The Empty Robot is one which aims to better understand what it means to be. 
// Exploring the nature of the mind, reality, and connected nature of the universe.

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    logo: file(relativePath: { eq: "img/theemptyrobot.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid (maxWidth: 300, maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    header: file(relativePath: { eq: "img/emptyrobot-background.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 1200, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    pbs: file(relativePath: { eq: "img/perfect-brilliant-stillness.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 720, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mirror: file(relativePath: { eq: "img/the-mirror.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 720, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { ne: true }, type: {eq: "post"} } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          timeToRead
          frontmatter {
            title
            date
            tags
            draft
            excerpt
            picture {
              childImageSharp {
                fixed(height: 400) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
            author
          }
          excerpt
          fields {
            layout
            slug
          }
        }
      }
    }
  }
`;


const HomePosts = css`
  @media (min-width: 795px) {
    .post-card-large {
      flex: 1 1 100%;
      flex-direction: row;
      padding-bottom: 40px;
      min-height: 280px;
      border-top: 0;
    }

    .post-card-large .post-card-title {
      margin-top: 0;
      font-size: 3.2rem;
    }

    .post-card-large:not(.no-image) .post-card-header {
      margin-top: 0;
    }

    .post-card-large .post-card-image-link {
      position: relative;
      flex: 1 1 auto;
      margin-bottom: 0;
      min-height: 380px;
    }

    .post-card-large .post-card-image {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .post-card-large .post-card-content {
      flex: 0 1 361px;
      justify-content: center;
    }

    .post-card-large .post-card-title {
      margin-top: 0;
      font-size: 3.2rem;
    }

    .post-card-large .post-card-content-link {
      /*padding: 0 0 0 40px;*/
    }

    .post-card-large .post-card-meta {
      /*padding: 0 0 0 40px;*/
    }

    .post-card-large .post-card-excerpt p {
      margin-bottom: 1.5em;
      font-size: 1.8rem;
      line-height: 1.5em;
    }
  }
`;

export default IndexPage;
